import request from "@/utils/request";
import {pauseEvent} from "ant-design-vue/lib/vc-slider/src/utils";

export const getGatewayUsableList = (payload) => {
  return request({
    url: '/api/v1/gateway/available/',
    method: 'GET',
    params: payload
  })
}

export const postGatewayAdd = (payload) => {
  return request({
    url: '/api/v1/gateway/',
    method: 'POST',
    data: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const putGatewayEdit = (payload) => {
  return request({
    url: `/api/v1/gateway/${payload.id}/`,
    method: 'PUT',
    data: {name: payload.name, group_id: payload.group_id},
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const deleteGateway = (payload) => {
  return request({
    url: `/api/v1/gateway/${payload.id}/`,
    method: 'DELETE',
    params: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const getGatewayList = (payload) => {
  return request({
    url: '/api/v1/gateway/',
    method: 'GET',
    params: payload
  })
}


export const getSensorList = (payload) => {
  return request({
    url: '/api/v1/gateway/sensor/',
    method: 'GET',
    params: payload
  })
}

export const putSensorEdit = (payload) => {
  return request({
    url: `/api/v1/gateway/sensor/${payload.id}/`,
    method: 'PUT',
    data: {name: payload.name},
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const getSensorDetails = (payload) => {
  return request({
    url: `/api/v1/gateway/sensor/${payload.id}/`,
    method: 'GET',
    params: payload
  })
}

export const getDetailsChart = (payload) => {
  return request({
    url: '/api/v1/gateway/sensor/data/',
    method: 'GET',
    params: payload
  })
}

export const getNotificationDetails = (payload) => {
  return request({
    url: `/api/v1/qy/wx/${payload.gateway_id}/${payload.show_id}`,
    method: 'GET',
    data: payload
  })
}

export const putSettingNotice = (payload) => {
  return request({
    url: `/api/v1/qy/wx/${payload.gateway_id}/${payload.show_id}`,
    method: 'PUT',
    data: {...payload, value: payload.value}
  })
}
