<template>
  <div class="full-container">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "full-container",
    methods: {

    }
  }
</script>

<style>
  .full-container {
    /*height: 100%;*/
    min-height: 626px;
    min-width: 1326px;
    padding: 12px 0px 0px 0px;
    overflow-y: auto;
    overflow: overlay;
  }
</style>