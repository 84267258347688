<template>
  <a-carousel class="carousel__modify"  v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </a-carousel>
</template>

<script>
  export default {
    name: "m-carousel"
  }
</script>

<style lang="less">

  .carousel__modify{
    height: 100%;
    .slick-slider{
      height: 100%;
    }
    .slick-list{
      height: 100%;
    }
    .slick-track{
      height: 100%;

    }
    .slick-slide{
      /*height: 100%;*/
      &>div{
        height: 100%;
      }
    }

    .slick-dots.slick-dots-bottom{
      top: -20px;
      /*right: 5px;*/
      text-align: right;
      /*transform: rotate(90deg);*/

    }
    .slick-dots li button{
      background: #E1EEF2;
    }

    .ant-carousel .slick-dots li.slick-active button:hover,
    .ant-carousel .slick-dots li.slick-active button:focus{
      opacity: initial;
      background: #E1EEF2;
    }

    .ant-carousel .slick-dots li.slick-active button:hover, .ant-carousel .slick-dots li.slick-active button:focus{
      opacity: 1;
    }

    /**/
    .slick-dots li button{
      width: 8px;
      height: 8px;
      background-color: #E1EEF2;
      border-radius: 50%;

    }
    .slick-dots li button:focus{
      opacity:.3;
    }


    .slick-dots li.slick-active button{
      width: 8px;
      height: 8px;
      background-color: #66FEFE;
    }
    .slick-dots li +.slick-dots li.slick-active {
      margin-top: 8px;
    }
  }
</style>