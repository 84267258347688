<template>
  <div class="empty">
    <slot name="img"></slot>
<!--    <img class="image" alt="text" src="../../assets/imgs/wusj_img@2x.png" :style="imageStyle" />-->
    <div class="desc">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
  import { Empty } from 'ant-design-vue'
  export default {
    name: 'empty-content',
    props: {
      // imageStyle: {
      //   type: Object,
      //   default: () => ({
      //     width: '140px',
      //     height: '140px',
      //   })
      // },
      // desc: {
      //   type: String,
      //   // default: '暂无数据',
      // }
    },
    data(){
      return {
        image: Empty.PRESENTED_IMAGE_SIMPLE,
      }
    }
  }
</script>

<style lang="less">
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    //.image {
    //  margin-top: 80px;
    //  width: 140px;
    //  height: 140px;
    //  fill: #658C99;
    //  overflow: hidden;
    //}

    .desc {
      //line-height: 60px;
      font-size: 14px;
      color: #658C99;
      text-align: center;
      margin-top: 10px;
    }
  }
</style>