import Vue from 'vue'
import Vuex from 'vuex'

import gatewayManagement from './modules/gatewayManagement'
import groupManagement from './modules/groupManagement'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    gatewayManagement,
    groupManagement,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
})
