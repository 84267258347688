import {
  getGatewayUsableList,
  postGatewayAdd,
  putGatewayEdit,
  deleteGateway,
  getGatewayList,
  getSensorList,
  putSensorEdit,
  getSensorDetails,
  getDetailsChart,
  getNotificationDetails,
  putSettingNotice
} from "@/api/gatewayManagement";
import {message} from "ant-design-vue";

const state = {
  // loading: {},

  gatewayUsableList: null,
  group_name: '',
  select_id: '',
  group_id: '',
  gateway_id: '',
  gateway_name: '',
  gateway_state: '',
  gatewayList: null,
  sensorList: null,
  sensor_id: '',
  sensor_show_id: '',
  sensor_name: '',
  sensor_state: '',
  sensorDetails: null,
  detailsChart: null,
  notificationDetails: null,
}

const mutations = {
  updateState(state, payload) {
    if (typeof payload !== 'object') {
      return
    }
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }
}

const actions = {
  getGatewayUsableList({state, commit}, payload) {
    return new Promise((resolve, reject) => {
     getGatewayUsableList(payload).then(res => {
       if(res.code === 200){
         commit('updateState', {gatewayUsableList: res.data})
       }
       resolve(res)
     }).catch(err => {
       reject(err)
     })
    })
  },

  getGatewayList({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getGatewayList(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {gatewayList: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  postGatewayAdd({state, commit}, payload) {
    return new Promise((resolve, reject) => {
      // commit('updateState',{loading: {...state.loading, postGatewayAdd: true }})
      postGatewayAdd(payload).then((res) => {
        // commit('updateState',{loading: {...state.loading, postGatewayAdd: false }})
        const { code, data } = res
        if(code === 200){
          message.success('添加成功')
        }
        resolve(res)
      }).catch((err) => {
        // commit('updateState',{loading: {...state.loading, postGatewayAdd: false }})
        reject(err)
      })
    })
  },

  putGatewayEdit({state, commit}, payload){
    return new Promise((resolve, reject) => {
      putGatewayEdit(payload).then(res => {
        if(res.code === 200){
          message.success('修改成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteGateway({state, commit}, payload){
    return new Promise((resolve, reject) => {
      deleteGateway(payload).then(res => {
        if(res.code === 200){
          message.success('删除成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getSensorList({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getSensorList(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {sensorList: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  putSensorEdit({state, commit}, payload){
    return new Promise((resolve, reject) => {
      putSensorEdit(payload).then(res => {
        if(res.code === 200){
          message.success('修改成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getSensorDetails({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getSensorDetails(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {sensorDetails: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getDetailsChart({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getDetailsChart(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {detailsChart: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getNotificationDetails({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getNotificationDetails(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {notificationDetails: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  putSettingNotice({state, commit}, payload){
    return new Promise((resolve, reject) => {
      putSettingNotice(payload).then(res => {
        if(res.code === 200){
          message.success('告警通知设置成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}