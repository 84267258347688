<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <template #renderEmpty>
        <empty-content>
          <template slot="img">
            <img class="imageBackdrop" alt="text" src="../src/assets/imgs/wusj_img@2x.png" />
          </template>
          <template slot="desc">
            <span>暂无数据</span>
          </template>
        </empty-content>
      </template>
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  export default {
    name: "App",
    components: {

    },
    data() {
      return {
        locale: zhCN,
      }
    },
    created() {
      setInterval(() => {
        // console.log('刷新')
      },60000)
    }
  };
</script>
<style lang="less">
  #app{
    height: 100%;
    min-width: 1366px;

    .imageBackdrop {
      //margin-top: 100px;
      width: 140px;
      height: 140px;
      fill: #658C99;
      overflow: hidden;
    }
  }

  .ant-message {
    color: #337587!important;
  }
</style>
