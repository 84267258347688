import Vue from 'vue'
import VueRouter from 'vue-router'

// 安装插件
Vue.use(VueRouter)

// 创建路由对象
const routes = [
    {
        path: '',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import('../views/home/index'),
        // component: () => import('../views/layout/index'),
        // component: () => import('../views/test/index'),

        meta: {
            title: '首页'
        }
    },
    {
        path: '/gateway',
        component: () => import('../views/gateway/index'),
        meta: {
            title: '网关管理'
        }
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

router.beforeEach((to, from, next) => {
    document.title = to.matched[0].meta.title
    next()
})

// 导出router
export default router