<template>
  <div class="card-container">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "card-container",
    methods: {

    }
  }
</script>

<style lang="less">
  .card-container {
    z-index: 0;
    position: relative;

    &:before {
     display: block;
     content: '';
     z-index: -1;
     position: absolute;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     border-top: 20px solid transparent;
     border-bottom: 20px solid transparent;
     border-right: 20px solid transparent;
     border-left: 20px solid transparent;
     border-image: url('~@/assets/imgs/dik_tr_img@2x.png') 40 40 fill;
     background: #000;
   }
  }
</style>