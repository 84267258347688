<template>
  <a-carousel class="carousel-vertical__modify"
              ref="carousel"
              @mousewheel.native="handleMouse"
              v-bind="$attrs"
              v-on="$listeners">
    <slot></slot>
  </a-carousel>
</template>

<script>
  export default {
    name: "m-carousel1",
    mounted() {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleMouse(){
        this.$refs.carousel.next()
      },
      handleResize() {
     const parent = document.querySelector('.carousel-vertical__modify.ant-carousel-vertical')
        const height = document.querySelector('.carousel-vertical__modify.ant-carousel-vertical').offsetHeight + 'px'
        parent.querySelectorAll('.slick-slide').forEach((item, index) => {
          item.style.width = '100%'
        })
      }
    }
  }


</script>

<style lang="less">
  .ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
    border-radius: 8px;
  }

  /*.slick-track{*/
  /*  transition-duration: 0.1s;*/
  /*}*/
  .carousel-vertical__modify {
    /*height: 100%;*/

    margin: 0;
    padding: 0;

    .slick-vertical .slick-slide {
      border: none;
    }

    .slick-slider {
      /*height: 100%;*/
    }

    .slick-list {
      // 确切的显示高度
      /*height: 800px;*/
      /*height: 100%;*/
      border-radius: 8px;
    }

    .slick-track {
      height: 100%;
      transition-duration: 0.3s;
      transition: -webkit-transform 300ms ease 0s;
    }

    .slick-slide {
      // 确切的内容高度
      /*height: 800px;*/
      transition-duration: 0.3s;
      /*&>div{*/
      /*  height: 100%;*/
      /*}*/
    }

    .slick-dots.slick-dots-right {
      width: 8px;
      top: 0;
      transform: initial;
      right: -14px;
    }

    .slick-dots li {
      margin: 0;
      padding: 0;
      vertical-align: initial;
      display: inherit;
    }

    .slick-dots li + li {
      margin-top: 5px;
    }

    .slick-dots li button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #E1EEF2;
    }

    .slick-dots li button:focus {
      opacity: .3;
    }

    .slick-dots li.slick-active button {
      width: 8px;
      height: 8px;
      background-color: #66FEFE;
    }
  }

</style>