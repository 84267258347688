<template>
  <a-select
    class="select_modify"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </a-select>
</template>

<script>
  export default {
    name: "m-select",
    model: {
      event: 'change'
    }
  }
</script>

<style lang="less">
  .select_modify {
    .ant-input-affix-wrapper {
      background-color: transparent;
    }

    .ant-select-selection {
      background-color: transparent;
    }

    .ant-select-arrow {
      color: #B4CDD4;
    }

    .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
      color: #658C99;
    }

    .ant-select-dropdown-menu-item {
      background-color: #05181E;
    }

    .ant-select-dropdown-menu {
      padding: 0 0;
      background-color: #05181E;
      border: 1px solid #337587;
    }

    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #05181E;
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #153B42;
    }

    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected{
      color: #66ffff;
    }

    .ant-empty {
      color: #B4CDD4;
    }
  }
</style>