import request from "@/utils/request";

export const postGroupAdd = (payload) => {
  return request({
    url: '/api/v1/group/',
    method: 'POST',
    data: {name: payload.name},
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const putGroupEdit = (payload) => {
  return request({
    url: `/api/v1/group/${payload.id}/`,
    method: 'PUT',
    data: {name: payload.name},
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const deleteGroup = (payload) => {
  return request({
    url: `/api/v1/group/${payload.id}/`,
    method: 'DELETE',
    params: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const getGroupList = () => {
  return request({
    url: '/api/v1/group/',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}