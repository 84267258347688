<template>
  <a-pagination
    class="pagination_modify"
    size="small"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <slot></slot>
  </a-pagination>
</template>

<script>
  export default {
    name: "m-pagination",
    model: {
      prop: 'visible',
      event: 'change'
    }
  }
</script>

<style lang="less">
  .pagination_modify {
    .ant-pagination-item{
      border-color: transparent;
    }
  }

  .ant-table-pagination.ant-pagination {
    float: none;
  }

  .ant-pagination {
    text-align: center;
    padding: 8px 0 0 0;
  }

  .ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-linl {
    color: #E1EEF2;
  }

  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: transparent;
  }

  .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border-color: transparent;
    background-color: #06181D;
  }

  .ant-pagination-item-active {
    border: 1px solid #66ffff !important;
    border-color: #06181D;
  }

  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border: 1px solid #66ffff;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #E1EEF2;
  }

  .ant-pagination-item a {
    padding: 0 0;
  }

  .ant-pagination-item-active a {
    padding: 0 0;
  }

  .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    min-width: 22px;
  }
</style>