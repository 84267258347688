<template>
  <a-list
    class="list_modify"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </a-list>
</template>

<script>
  export default {
    name: "m-list"
  }
</script>

<style lang="less">
  .list_modify {

  }

  .ant-list-item {
    padding: 0 0 4px 0;
  }

  .ant-list-item-meta {
    padding: 8px 0;
  }

  .ant-list-item-meta-avatar {
    margin-left: 16px;
  }

  .img {
    width: 46px;
    height: 46px;
    border-radius: 4px;
  }

  .online {
    width: 30px;
    height: 18px;
    color: #020511;
    text-align: center;
    font-size: 12px;
    background-color: #6CE117;
    border-radius: 4px 0 4px 0;
    position: absolute;
    top: 28px;
    left: 16px;
  }

  .offline {
    width: 30px;
    height: 18px;
    color: #020511;
    text-align: center;
    font-size: 12px;
    background-color: #B4C1D4;
    border-radius: 4px 0 4px 0;
    position: absolute;
    top: 28px;
    left: 16px;
  }

  .ant-list-item-meta-title > a:hover {
    color: #E1EEF2;
  }

  .ant-list-item-meta:hover {
    background: #153B42;
    cursor: pointer;
  }

  .ant-list-item-meta:active {
    background: #153B42;
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    color: #658C99;
    line-height: 16px;
  }

  .ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border-bottom: 1px solid transparent;
  }

  .ant-list-pagination {
    margin-top: 12px;
    text-align: center;
  }
</style>