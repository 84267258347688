import {
  postGroupAdd,
  putGroupEdit,
  deleteGroup,
  getGroupList,
} from "@/api/groupManagement";
import {message} from "ant-design-vue";

const state = {
  groupList: null,
}

const mutations = {
  updateState(state, payload) {
    if (typeof payload !== 'object') {
      return
    }
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }
}

const actions = {
  postGroupAdd({state, commit}, payload){
    return new Promise((resolve, reject) => {
      postGroupAdd(payload).then(res => {
        if(res.code === 200){
          message.success('添加成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  putGroupEdit({state, commit}, payload){
    return new Promise((resolve, reject) => {
      putGroupEdit(payload).then(res => {
        if(res.code === 200){
          message.success('修改成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteGroup({state, commit}, payload){
    return new Promise((resolve, reject) => {
      deleteGroup(payload).then(res => {
        if(res.code === 200){
          message.success('删除成功')
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getGroupList({state, commit}, payload){
    return new Promise((resolve, reject) => {
      getGroupList(payload).then(res => {
        if(res.code === 200){
          commit('updateState', {groupList: res.data})
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}