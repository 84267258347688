<template>
  <a-dropdown
      class="dropdown_modify"
      v-bind="attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </a-dropdown>
</template>

<script>
  export default {
    name: "m-dropdown",
    model: {
      event: 'change'
    }
  }
</script>

<style lang="less">
  .dropdown_modify{

  }

  .ant-dropdown-menu {
    border: 1px solid #337587;
    background-color: #05181E;
  }

  .ant-dropdown-menu-item {
    color: #B4CDD4;
    font-size: 14px;
    line-height: 19px;
    padding: 7px 16px 6px 16px;
    border-bottom: none;
  }

  .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
    color: #B4CDD4;
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: #153B42;
  }

  .overlayClass {
    .ant-dropdown-menu{
      top: 8px;
    }

    .ant-dropdown-menu {
      padding: 0;
    }

    .ant-dropdown-menu-item + .ant-dropdown-menu-item {
      border-top: 1px solid #337587;
    }
  }
</style>