<template>
  <a-input
    class="ant-input-transparency_modify"
    v-bind="$attrs"
    v-on="listeners"
    @input="(event) => $listeners['input'](event.target.value)"
  >
    <slot></slot>
    <template slot="prefix">
      <slot name="prefix"></slot>
    </template>
  </a-input>
</template>

<script>
  export default {
    name: "m-input-search",
    model: {
      event: 'input'
    },
    computed:{
      listeners(){
        const { input, ...rest} = this.$listeners
        return rest
      }
    }
  }
</script>

<style lang="less">
  .ant-input-transparency_modify {
    .ant-input {
      border: none;
      background-color: transparent;
    }

    .ant-input:focus {
      box-shadow: none;
    }
  }
</style>