<template>
  <a-select
      class="select-transparency_modify"
      notFoudContent="暂无数据"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </a-select>
</template>

<script>
  export default {
    name: "m-select-transparency",
    model: {
      event: 'change'
    },
  }
</script>

<style lang="less">
  .select-transparency_modify {
    .ant-select-selection--single .ant-select-selection__rendered {
      margin-top: 2px;
    }

    .ant-input-affix-wrapper {
      background-color: transparent;
    }

    .ant-select-selection {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }

    .ant-select-arrow {
      color: #B4CDD4;
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected{
    color: #66ffff;
  }

  .ant-select-dropdown-menu-item {
    background-color: #05181E;
    color: #B4CDD4;
  }

  .ant-select-dropdown-menu {
    padding: 0 0;
    background-color: #05181E;
    border: 1px solid #337587;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #05181E;
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #153B42;
  }

  .ant-empty {
    color: #B4CDD4;
  }
</style>