<template>
  <a-date-picker
    class="date-picker_modify"
    placeholder="请选择日期"
    v-bind="$attrs"
    today="今天"
    v-on="$listeners"
  >
    <slot></slot>
  </a-date-picker>
</template>

<script>
  export default {
    name: "m-date-picker",
    model: {
      event: 'change'
    }
  }
</script>

<style lang="less">
  .ant-calendar {
    width: 284px;
    color: #E1EEF2;
    border: 1px solid #337587;
    background-color: #05181E;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background: #66FFFF;
    color: #020511;
  }

  .ant-calendar-date:hover {
    background-color: #337587;
    border: 4px;
  }

  .ant-calendar-today .ant-calendar-date {
    font-weight: normal;
  }


  .ant-calendar-last-month-cell .ant-calendar-date, .ant-calendar-next-month-btn-day .ant-calendar-date, .ant-calendar-last-month-cell .ant-calendar-date:hover, .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    color: #658C99;
  }

  .ant-calendar-input {
    width: auto;
    height: auto;
    background-color: transparent;
  }

  .ant-calendar-input-wrap {
    border-bottom: 1px solid #337587;
  }

  .ant-calendar-header {
    border-bottom: 1px solid #337587;
  }

  .ant-calendar-header .ant-calendar-century-select, .ant-calendar-header .ant-calendar-decade-select, .ant-calendar-header .ant-calendar-year-select, .ant-calendar-header .ant-calendar-month-select{
    color: #E1EEF2;
  }

  .ant-calendar-footer {
    border-top: 1px solid #337587;
  }

  .ant-calendar-today-btn {
    color: #66ffff;
  }

  .ant-calendar-year-panel {
    background-color: #05181E;
  }

  .ant-calendar-year-panel-header a:hover {
    color: #E1EEF2;
  }

  .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select, .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select, .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
    color: #E1EEF2;
  }

  .ant-calendar-year-panel-header {
    border-bottom: 1px solid #337587;
  }

  .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year, .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
    color: #658C99;
  }

  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
    color: #020511;
  }

  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
    color: #E1EEF2;
    background: #337587;
  }

  .ant-calendar-year-panel-year:hover {
    background: #337587;
  }

  .ant-calendar-decade-panel {
    background-color: #05181E;
  }

  .ant-calendar-decade-panel-header {
    border-bottom: 1px solid #337587;
  }

  .ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade, .ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
    color: #658C99;
  }

  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    color: #020511;
  }

  .ant-calendar-decade-panel-decade:hover {
    color: #E1EEF2;
    background: #337587;
  }

  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
    color: #E1EEF2;
    background: #337587;
  }

  .ant-calendar-month-panel {
    background-color: #05181E;
  }

  .ant-calendar-month-panel-header {
    border-bottom: 1px solid #337587;
  }

  .ant-calendar-month-panel-year-select-content {
    color: #E1EEF2;
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    color: #020511;
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
   color: #E1EEF2;
   background: #337587;
  }

  .ant-calendar-month-panel-month:hover {
    color: #E1EEF2;
    background: #337587;
  }

  .ant-calendar-picker-icon {
    color: #66ffff;
  }

  .ant-calendar-picker-clear {
    color: #337587;
    background-color: #020511;
  }

  .ant-calendar-picker-clear:hover {
    color: #337587;
    background-color: #020511;
  }
</style>