<template>
  <a-form-model
    class="form-model_modify"
    ref="form"
    :hideRequiredMark="true"
    :labelCol="labelCol"
    :wrapperCol="wrapperCol"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </a-form-model>
</template>

<script>
  export default {
    name: "m-form",
    data(){
      return {
        labelCol: { span: 1 },
        wrapperCol: { span: 1 },
      }
    },
  mounted(){
    this.clearValidate = this.$refs.form.clearValidate
    this.validate = this.$refs.form.validate
  },
}
</script>

<style lang="less">
  .form-item_modify {
    .ant-form-item {
      margin-bottom: 24px;
    }
  }
</style>