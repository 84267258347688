import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import 'default-passive-events'

import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
Vue.use(antd)

import './core/components'

import './assets/css/normalize.css'

import '@/core/components'
import '@/core/echarts'

import './style/reset.less'


const bus = new Vue()
Vue.prototype.$bus = bus


Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
