<template>
  <a-drawer
    class="drawer_modify"
    placement="right"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="content"></slot>
  </a-drawer>
</template>

<script>
  export default {
    name: "m-drawer",
    model: {
      prop: 'visible',
      event: 'change'
    }
  }
</script>

<style lang="less">
  .drawer_modify {
    .ant-drawer {
      height: 100%;
    }

    .ant-drawer-header {
      border-bottom: none;
      background-color: #255461;
      height: 50px;
      border-radius: 0;
      padding: 15px 20px 14px 20px;
    }

    .ant-drawer-title {
      font-weight: bold;
      font-size: 16px;
      color: #E1EEF2;
    }

    .ant-drawer-close {
      color: #B4CDD4;
    }

    .ant-drawer-content {
      border-radius: 0;
      height: 100%;
      background-color: #0C2F33;
    }

    .ant-modal-footer {
      border-top: none;
    }

    .ant-btn {
      padding: 0 0;
    }
  }
</style>
