<template>
  <a-form-model
    ref="form"
    class="form_modify"
    :hideRequiredMark="true"
    :labelCol="{...labelCol, ...$attrs.labelCol}"
    :wrapperCol="{...wrapperCol, ...$attrs.wrapperCol}"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </a-form-model>
</template>

<script>
  export default {
    name: 'm-modal-form',
    data(){
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 8 }
      }
    },
    mounted() {
      this.clearValidate = this.$refs.form.clearValidate
      this.validate = this.$refs.form.validate
    }
  }
</script>

<style lang="less">
  .form-dropdown__modify{

  }
</style>