<template>
  <a-modal
    class="modal_modify"
    cancelText="取消"
    :cancelButtonProps="{props: {type: 'ghost'}}"
    centered
    :mask="true"
    :maskClosable="false"
    :keyboaed="false"
    :destroyOnClose="true"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="content"></slot>
  </a-modal>
</template>

<script>
  export default {
    name: "m-modal",
    model: {
      prop: 'visible',
      event: 'change'
    }
  }
</script>

<style lang="less">
  .modal_modify {
    .ant-modal-content {
      z-index: 0;
      position: relative;
      background-color: transparent;

      &:before {
        display: block;
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        border: 50px solid transparent;
        border-image: url("~@/assets/imgs/dik_tc_img@2x.png") 100 fill;
      }
    }

    .ant-modal-header {
      padding: 10px 20px;
      border-bottom: none;
      background-color: transparent;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 16px;
      color: #E1EEF2;
    }

    .ant-modal-body {
      color: #E1EEF2;
      font-size: 14px;
      background: 24px 24px 4px;
    }

    .ant-modal-close {
      width: 22.63px;
      height: 22.63px;
      position: absolute;
      right: 18px;
      color: #B4CDD4;
    }

    .ant-modal-close-x {
      line-height: 47px;
      width: 22.63px;
      height: 22.63px;
    }

    .ant-form-item-control {
      width: 312px;
    }

    .ant-modal-footer {
      border-top: none;
      padding: 0 24px 24px;
    }

    .ant-btn {
      border: 1px solid #337587;
      width: 90px;
      height: 32px;
    }

    .ant-btn.ant-btn-primary {
      color: #0C2F33;
    }

    .ant-btn.ant-btn-danger {
      color: #0C2F33;
    }

    .ant-modal-footer button + button {
      margin-left: 16px;
    }
  }
</style>