import Vue from 'vue'

import MDatePicker from "@/components/AntdComponent/MDatePicker"
import MDrawer from "@/components/AntdComponent/MDrawer";
import MDropdown from "@/components/AntdComponent/MDropdown";
import MForm from "@/components/AntdComponent/MForm";
import MInput from "@/components/AntdComponent/MInput";
import MInputSearch from "@/components/AntdComponent/MInputSearch";
import MList from "@/components/AntdComponent/MList";
import MModal from "@/components/AntdComponent/MModal";
import MModalForm from "@/components/AntdComponent/MModalForm";
import MPagination from "@/components/AntdComponent/MPagination";
import MSelect from "@/components/AntdComponent/MSelect";
import MSelectTransparency from "@/components/AntdComponent/MSelectTransparency";
import MTable from "@/components/AntdComponent/MTable";

Vue.component('m-date-picker', MDatePicker)
Vue.component('m-drawer', MDrawer)
Vue.component('m-dropdown', MDropdown)
Vue.component('m-form', MForm)
Vue.component('m-input', MInput)
Vue.component('m-input-search', MInputSearch)
Vue.component('m-list', MList)
Vue.component('m-modal', MModal)
Vue.component('m-modal-form', MModalForm)
Vue.component('m-pagination', MPagination)
Vue.component('m-select', MSelect)
Vue.component('m-select-transparency', MSelectTransparency)
Vue.component('m-table', MTable)


import FullContainer from "@/components/LayoutComponent/FullContainer";
import CardContainer from "@/components/LayoutComponent/CardContainer";
import CardItem from "@/components/LayoutComponent/CardItem";

Vue.component('full-container', FullContainer)
Vue.component('card-container', CardContainer)
Vue.component('card-item', CardItem)

import EmptyContent from "@/components/EmptyContent/EmptyContent";

Vue.component('empty-content', EmptyContent)
import MCarousel from "../components/MCarousel";
Vue.component('m-carousel', MCarousel);

import MCarousel1 from "../components/MCarousel1";
Vue.component('m-carousel1', MCarousel1)

import MCarousel2 from "../components/MCarousel2";
Vue.component('m-carousel2', MCarousel2)