<template>
  <div class="card-item">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "card-item",
    methods: {

    }
  }
</script>

<style>
  .card-item {
    height: 100%;
  }
</style>