<template>
  <a-table
      class="table_modify"
      :columns="columns"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template v-for="column in columns" :slot="column.scopedSlots?column.scopedSlots.customRender:''" slot-scope="text,record">
      <slot :name="column.scopedSlots?column.scopedSlots.customRender:''" v-bind="{text,record}" ></slot>
    </template>
    <template slot="expandedRowRender" slot-scope="record,index">
      <slot name="expandedRowRender" v-bind="{record,index}" ></slot>
    </template>
  </a-table>
</template>

<script>
  export default {
    name: "m-table",
    props: {
      columns: {
        type: Array,
        default: () => []
      }
    },
  }
</script>

<style lang="less">
  .table_modify {
    .ant-table-thead > tr > th {
      font-weight: bold;
      color: #B4CDD4;
      background-color: #062A2E;
      height: 28px;
      padding: 0 16px;
      border-bottom: 1px solid #225461;
    }

    .ant-table-tbody > tr > td {
      color: #B4CDD4;
      border-bottom: 1px solid #225461;
      padding: 8px 16px;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #153B42;
    }

    tr.ant-table-expanded-row, tr.ant-table-expanded-row:hover {
      background: #0B2329;
      height: 150px;
    }

    .ant-table-thead > tr:first-child > th.ant-table-expand-icon-th {
      width: 0;
      min-width: initial;
      padding: 0;
    }

    .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell {
      width: 0;
      min-width: initial;
      padding: 0;
    }

    .ant-table-placeholder {
      background: transparent;
      border-top: none;
      border-bottom: none;
    }

    .ant-table-header {
      background: transparent;
    }

    .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
      border: none;
    }

    .ant-table-fixed-header .ant-table-scroll .ant-table-header{
      overflow: auto!important;
      margin-bottom: 0!important;
    }

    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      background: transparent;
      overflow: auto!important;
    }
  }
</style>