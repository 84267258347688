<template>
  <a-input
      ref="input"
      class="input_modify"
      v-bind="$attrs"
      v-on="listeners"
      @input="(event) => $listeners['input'](event.target.value)"
  >
    <slot></slot>
    <template slot="prefix" >
      <slot name="prefix"></slot>
    </template>
    <template slot="suffix" >
      <slot name="suffix"></slot>
    </template>
  </a-input>
</template>

<script>
  export default {
    name: "m-input",
    model: {
      event: 'input'
    },
    methods: {
      focus() {
        this.$refs.input.focus()
      }
    },
    computed:{
      listeners(){
        const { input, ...rest} = this.$listeners
        return rest
      }
    }
  }
</script>

<style lang="less">
  .input_modify {
    .ant-input {
      border: 1px solid #337587;
      background-color: transparent;
    }

    .has-error .ant-input-affix-wrapper {
      background-color: #0000;
    }
  }

  .ant-input::placeholder{
    color: #658C99;
  }

  .has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: transparent;
  }

  .has-error .ant-input, .has-error .ant-input:hover {
    background-color: transparent;
  }

  .ant-input[disabled] {
    color: #658C99;
    background-color: #154448;
  }

  .ant-input-clear-icon {
    color: #337587;
  }
</style>