import axios from 'axios'

// 每一次请求都会加这个请求头
// axios.defaults.headers['Content-Type'] = 'application/json'

const request = axios.create({
    timeout: 5000,
    baseURL: window.location.origin,
    // baseURL: 'http://192.168.0.189:8080/api/v1/',
})


// 添加请求拦截器
request.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(response => {
    return response.data
}, error => {
    return Promise.reject(error)
})

export default request