import Vue from 'vue'
import * as echarts from 'echarts/core'

import { LineChart } from "echarts/charts";

import {
    TooltipComponent,
    GridComponent,
} from "echarts/components";

import { UniversalTransition } from 'echarts/features';

import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

Vue.prototype.$echarts = echarts